.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.section-one {
  margin-top: 200px !important;
}

.m-top {
  margin-top: 50px;
}

.google {

}

.apple {
margin-left: 30px !important;
}

/* img {
  width: 350px !important;
  margin: auto;
} */

.m-top-130 {
  margin-top: 130px !important;
}
.m-top-60 {
  margin-top: 60px !important;
}
.m-top-50 {
  margin-top: 50px !important;
}
.m-top-80 {
  margin-top: 80px !important;
}
.m-top-30 {
  margin-top: 30px !important;
}

.bigtext {
 font-size: 48px;
}

.smalltext {
  font-size: 24px;
 }

 .mediumtext {
  font-size: 32px;
 }

 .tinytext {
  font-size: 16px;
 }

 .showhidetext {
   display: block;
 }

 .maxwidthauto{
   max-width: 1200px !important;
   margin: auto !important;
   padding: 0px !important;
 }

 /* .main {
   padding: 0px !important;
 } */

@media only screen and (max-width: 600px) {
  .maxwidthauto{
    max-width: 100% !important;
    margin: auto !important;
    padding: 20px !important;
  }

  /* .main {
    padding: 20px !important;
  } */

  .google {

  }
  
  .apple {
  margin-left: 0px !important;
  }

  .MuiGrid-spacing-xs-8 > .MuiGrid-item {
    padding: 0px !important;
  }
  .section-one {
    margin-top: 20px !important;
  }
  .bigtext {
    font-size: 20px;
   }
   .m-top {
     margin-top: 20px;
   }
   .m-top-130 {
    margin-top: 20px !important;
  }
  .m-top-30 {
    margin-top: 5px !important;
  }
  .m-top-60{
    margin-top: 20px !important;
  }
  .m-top-50 {
    margin-top: 20px !important;
  }
  .m-top-80{
    margin-top: 20px !important;
  }
   .smalltext {
    font-size: 14px;
   }

   .mediumtext {
    font-size: 16px;
   }
  
   .tinytext {
    font-size: 12px;
   }

   .showhidetext {
     display: none;
   }
}

/* .MuiDialog-paper {
  background-color: transparent !important;
} */

.light-progress-bar {
  height: 10px;
  background: #F5EAF9;
  border-radius: 4px;
}

.progress-bar {
/* width: 57px; */
height: 10px;
background: #E53A35;
border-radius: 4px;
}

.App-link {
  color: #61dafb;
}

.code-input {
  padding: 15px;
  border-radius: 8px;
  border : 1px solid #BCBBBB;
  width: 35px;
  height: 35px;
  text-align: center;
  margin: 8px;
}

.selected {
  background-color: #662E50 !important;
  color: #fff !important;
}

.review {
  padding: 1rem;
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  margin: 8px;
}

.MuiTabs-indicator {
  background-color:#662E50 !important;
}

.Mui-selected {
  color: #662E50 !important;
}

.MuiBox-root  {
  padding: 0px !important;
}

.panes {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #662E50 !important;
  border-radius: 8px !important;
  padding: 18px 12px !important;
}

.selected-btn {
  background-color: #fff !important;
  border: 1px solid #662E50 !important;
  color: #662E50 !important;
}

.tab-btn {
  padding: 8px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
